/* @font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
} */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/montserrat-regular.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/montserrat-semibold.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/josefinsans-bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Zen Antique';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/zenantique-regular-webfont.woff2') format('woff2');
  font-display: swap;
}
