/* .MuiFormControl-marginNormal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.MuiInputBase-input {
  font-size: 16px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.Autocomplete-singleValue-7,
.MuiTypography-root {
  font-size: 16px !important;
}

.MuiInputBase-root {
  padding-right: 4px !important;
}

@media (min-width: 760px) {
  .MuiInputBase-input {
    font-size: 20px !important;
  }

  .Autocomplete-singleValue-7,
  .MuiTypography-root {
    font-size: 20px !important;
  }
}

.MuiPaper-root {
  min-width: 300px;
}
*/

.MuiAutocomplete-clearIndicator {
  display: none !important;
}
